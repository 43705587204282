import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records, utils } from '@ims/1edtech-frontend-common'

export const TRUSTED_APPS_RECORD = 'trustedApplications'

const ensureString = (str: any) =>
  str === undefined || str === null ? '' : `${str}`
const ensureArray = (val: any) => (!val ? [] : utils.array.ensureArray(val))
const ensureNum = (d: any) => (d === undefined || d === null ? d : d * 1)

const fields = [
  'applicationId',
  'status',
  'logo',
  'title',
  'orgName',
  'certified',
  'vetted',
  'technicalNotes',
  'instructionalNotes',
  'licensingFees',
  'licensingExpirationDate',
  'rating',
  'surveyResults',
  'tags',
]
const prepareData = R.compose<any, any, any>(
  R.evolve({
    technicalNotes: ensureString,
    instructionalNotes: ensureString,
    licensingFees: ensureString,
    licensingExpirationDate: ensureNum,
    surveyResults: ensureArray,
  }),
  R.pickAll(fields),
)
const trustedApplications: records.modules.IModule = {
  record: TRUSTED_APPS_RECORD,
  responseDataKey: 'applications',
  getCreateResponseData: R.propOr({}, 'data'),
  schema: new normalizr.schema.Entity(
    TRUSTED_APPS_RECORD,
    {},
    {
      idAttribute: 'applicationId',
      processStrategy: (entity) => {
        return R.assoc('id', R.prop('applicationId', entity), entity)
      },
    },
  ),
  api: {
    create: () => 'trustedapps/organizations/applications',
    get: (id, params) => {
      if (params.parentRecordId)
        return `trustedapps/organizations/${params.parentRecordId}/applications/${id}`
      return `trustedapps/organizations/applications/${id}`
    },
    getList: (_, parentRecordId) => {
      if (parentRecordId)
        return `trustedapps/organizations/${parentRecordId}/applications/sharedwithme`
      return 'trustedapps/organizations/applications'
    },
    update: (id) => `trustedapps/organizations/applications/${id}`,
    prepareCreateData: (data: any[]) => ({
      applications: data.map((app) => ({
        applicationId: app.applicationId,
        ...prepareData(app),
      })),
    }),
    prepareUpdateData: prepareData,
    delete: (id) => `trustedapps/organizations/applications/${id}`,
  },
}

export default trustedApplications
