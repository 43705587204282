import React from 'react'
import * as R from 'ramda'
import { Transition } from '@headlessui/react'

import { Screen } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseTrustedAppsBreadCrumbs from 'domains/trustedApps/components/BaseTrustedAppsBreadCrumbs'
import AddTrustedAppsStepOne from 'domains/trustedApps/components/AddTrustedApps/AddTrustedAppsStepOne'
import AddTrustedAppsStepTwo from 'domains/trustedApps/components/AddTrustedApps/AddTrustedAppsStepTwo'
import { IAddTrustedAppSelection } from 'domains/trustedApps/components/AddTrustedApps/models/IAddTrustedAppSelection'

export default function AddTrustedAppsScreen() {
  const [step, setStep] = React.useState(0)
  const [selection, setSelection] = React.useState<IAddTrustedAppSelection[]>(
    [],
  )
  const addApp = React.useCallback(
    (applicationId: number) => {
      setSelection([
        ...selection,
        {
          applicationId,
          technicalNotes: '',
          instructionalNotes: '',
          licensingCoverage: [],
          licensingExpirationDate: 0,
        },
      ])
    },
    [selection],
  )

  const removeApp = React.useCallback(
    (applicationId: number) => {
      const index = R.findIndex(
        R.propEq('applicationId', applicationId),
        selection,
      )
      setSelection(R.remove(index, 1, selection))
    },
    [selection],
  )

  const updateApp = React.useCallback(
    (applicationId: number, updates: any) => {
      const index = R.findIndex(
        R.propEq('applicationId', applicationId),
        selection,
      )
      const existing = selection[index]
      const newSelection = R.remove(index, 1, selection)
      setSelection(
        R.insert(
          index,
          {
            ...existing,
            ...updates,
          },
          newSelection,
        ),
      )
    },
    [selection],
  )

  const onNext = () => setStep(step + 1)
  const onPrevious = () => setStep(step - 1)

  return (
    <Screen>
      <DocumentTitle title="TrustEd Apps" />
      <BaseTrustedAppsBreadCrumbs crumbs={[{ name: 'Add New' }]} />

      <Transition
        show={step === 0}
        unmount={false}
        enter="transition ease-in-out duration-450 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-450 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <AddTrustedAppsStepOne
          onNext={onNext}
          onPrevious={onPrevious}
          selection={selection}
          addApp={addApp}
          removeApp={removeApp}
          updateApp={updateApp}
        />
      </Transition>
      <Transition
        show={step === 1}
        unmount={false}
        enter="transition ease-in-out duration-450 transform"
        enterFrom="translate-x-full"
        enterTo="-translate-x-0"
        leave="transition ease-in-out duration-450 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <AddTrustedAppsStepTwo
          onNext={onNext}
          onPrevious={onPrevious}
          selection={selection}
          addApp={addApp}
          removeApp={removeApp}
          updateApp={updateApp}
          setSelection={setSelection}
        />
      </Transition>
    </Screen>
  )
}
