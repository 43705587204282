import { getRequest } from 'lib/api/api'
import { useQuery } from 'lib/utils/useQuery'
import { GetTrustedAppSurveyQuestionsResponse } from '../models/ITrustedAppsCustomQuestions'

const TRUSTED_APPS_SURVEYS_QKEY = 'TRUSTED_APPS_SURVEYS'

export function useQueryTrustedAppsSurveys() {
  return useQuery(TRUSTED_APPS_SURVEYS_QKEY, async () => {
    const data: GetTrustedAppSurveyQuestionsResponse = await getRequest(
      'trustedapps/organizations/applications/config/surveys',
    ).then((response) => response.data)

    return data.questions
  })
}
