import { getRequest } from 'lib/api/api'
import { useQuery } from 'lib/utils/useQuery'

const TRUSTED_APPS_TAGS_QKEY = 'TRUSTED_APPS_TAGS'

export function useQueryTrustedAppsTags() {
  return useQuery(TRUSTED_APPS_TAGS_QKEY, async () => {
    interface ResponseData {
      tags: string[]
    }

    const data: ResponseData = await getRequest(
      'trustedapps/organizations/applications/tags',
    ).then((response) => response.data)

    return data.tags
  })
}
