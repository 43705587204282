import mitt, { Emitter } from 'mitt'
import { useEffect, useState, useCallback } from 'react'

type CacheEvents<T> = {
  request: void
  success: T
  error: Error
}

interface Cache<T> extends Emitter<CacheEvents<T>> {
  data: T | undefined
  error: Error | undefined
  isFetching: boolean
}

const createQueryCacheItem = <T>(): Cache<T> => {
  const emitter = mitt<CacheEvents<T>>()

  return {
    ...emitter,
    data: undefined,
    isFetching: false,
    error: undefined,
  }
}

const queryCache: {
  [key in string]?: Cache<unknown>
} = {}

const cacheFetch = async <T>(fetcher: () => Promise<T>, cache: Cache<T>) => {
  if (!cache.isFetching) {
    try {
      cache.isFetching = true
      cache.emit('request')

      const data = await fetcher()

      cache.isFetching = false
      cache.data = data
      cache.emit('success', data)
    } catch (e) {
      cache.isFetching = false
      cache.error = e instanceof Error ? e : new Error('Unknown error')
      cache.emit('error', cache.error)
    }
  }
}

export const useQuery = <T>(key: string, fetcher: () => Promise<T>) => {
  if (!queryCache[key]) {
    queryCache[key] = createQueryCacheItem()
  }

  const cache = queryCache[key] as Cache<T>
  const [data, setData] = useState<T | undefined>(cache.data)
  const [loading, setFetching] = useState(cache.isFetching)

  const refetch = useCallback(
    () => cacheFetch(fetcher, cache),
    [cache, fetcher],
  )

  useEffect(() => {
    cache.on('success', (tags) => {
      setData(tags)
      setFetching(false)
    })

    cache.on('request', () => {
      setFetching(true)
    })

    return () => {
      cache.off('success')
      cache.off('request')
    }
  }, [cache, key])

  useEffect(() => {
    if (!data) {
      cacheFetch(fetcher, cache)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { data, loading: loading, refetch }
}
