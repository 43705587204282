import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { Formik } from 'formik'
import { Button } from 'lib/components'
import React, { useMemo } from 'react'
import { TrustedAppsCustomQuestionRow } from './TrustedAppsCustomQuestionRow'
import * as Yup from 'yup'
import { FormValues, FormSurveyQuestion } from './types'
import {
  TrustedAppQuestionType,
  UpdateTrustedAppSurveyQuestionsRequest,
} from 'domains/trustedApps/models/ITrustedAppsCustomQuestions'
import { ERROR_TOAST, showToast, SUCCESS_TOAST } from 'lib/utils/toast'
import { useQueryTrustedAppsSurveys } from 'domains/trustedApps/hooks/useQueryTrustedAppsSurveys'
import { useUpdateCustomQuestions } from 'domains/trustedApps/hooks/useUpdateCustomQuestions'

interface TrustedAppsCustomFieldsProps {
  height: number
}

const validationSchema = Yup.object().shape({
  questions: Yup.array().of(
    Yup.object().shape({
      questionText: Yup.string().required('Required'),
      responses: Yup.array()
        .of(
          Yup.object().shape({
            responseText: Yup.string().required('Required'),
          }),
        )
        .min(1, 'At least one response is required'),
    }),
  ),
})

export function TrustedAppsCustomFields({
  height,
}: TrustedAppsCustomFieldsProps) {
  const questions = useQueryTrustedAppsSurveys()
  const [putQuestions] = useUpdateCustomQuestions()

  const initialValues = useMemo((): FormValues => {
    if (!questions.data) return { questions: [] }

    const resolvedQuestions: FormSurveyQuestion[] = questions.data?.map(
      (q) => ({
        ...q,
        isCreated: false,
        responses: q.responses?.map((r) => ({ ...r, isCreated: false })) ?? [],
      }),
    )

    return { questions: resolvedQuestions }
  }, [questions])

  if (questions.loading) return null

  const onSubmit = async (values: FormValues) => {
    try {
      const data: UpdateTrustedAppSurveyQuestionsRequest = {
        questions: values.questions.map((question) => {
          const questionId = question.isCreated ? undefined : question.id

          return {
            id: questionId,
            questionType: TrustedAppQuestionType.SINGLE_RESPONSE_FROM_LIST,
            hideFromTeachers: false,
            questionText: question.questionText,
            responses: question.responses.map((response) => {
              const responseId = response.isCreated ? undefined : response.id

              return {
                id: responseId,
                responseText: response.responseText,
              }
            }),
          }
        }),
      }

      await putQuestions(data)
      showToast(SUCCESS_TOAST, 'Custom Fields saved successfully')
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      showToast(ERROR_TOAST, 'Failed to save Custom Fields')
    }
  }

  return (
    <DetailsTopWidget
      title="TrustEd App Fields"
      dataTest="trustedapps-custom-fields-widget"
      height={height}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, setFieldValue, values, isSubmitting }) => {
          const createNewQuestion = () => {
            const newQuestion: FormSurveyQuestion = {
              id: Math.random(),
              questionText: '',
              responses: [
                {
                  id: Math.random(),
                  responseText: '',
                  isCreated: true,
                },
              ],
              isCreated: true,
            }

            setFieldValue('questions', [...values.questions, newQuestion])
          }

          return (
            <form
              style={{ width: '100%' }}
              noValidate={true}
              data-test="login-form"
            >
              <div className="flex flex-col gap-6 px-4">
                {values.questions.map((question) => {
                  return (
                    <TrustedAppsCustomQuestionRow
                      key={question.id}
                      question={question}
                    />
                  )
                })}
              </div>
              <div className="flex justify-between mt-4">
                <Button type="button" onClick={createNewQuestion}>
                  Add Question
                </Button>
                <Button
                  type="button"
                  variant="complete"
                  onClick={handleSubmit}
                  pending={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </form>
          )
        }}
      </Formik>
    </DetailsTopWidget>
  )
}
