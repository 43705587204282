import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { Input, Text, Icon, Button, FormErrorMessage } from 'lib/components'
import React from 'react'
import { FormValues, FormSurveyQuestion } from './types'

interface QuestionResponseProps {
  response: { id: number; responseText: string }
  responseIndex: number
  questionId: number
  onChangeText: (text: string) => void
  onRemove: () => void
}

const QuestionResponse = ({
  response,
  responseIndex,
  questionId,
  onChangeText,
  onRemove,
}: QuestionResponseProps) => {
  const formik = useFormikContext<FormValues>()
  const index = formik.values.questions.findIndex((q) => q.id === questionId)

  return (
    <div className="flex flex-col gap-1">
      <div
        key={response.id}
        className="flex items-center gap-1 rounded:shadow-lg rounded-md"
      >
        <Input
          value={response.responseText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChangeText(event.target.value)
          }}
          placeholder="Option"
        />
        <button
          className="w-8 h-8 rounded-full flex-shrink-0 flex items-center justify-center"
          onClick={onRemove}
        >
          <i className="fas fa-trash text-charcoal hover:text-error-40" />
        </button>
      </div>
      <FormErrorMessage
        name={`questions.${index}.responses.${responseIndex}.responseText`}
        showRequiredOnlyOnSumbit
      />
    </div>
  )
}

interface TrustedAppsCustomQuestionRowProps {
  question: FormSurveyQuestion
}

export function TrustedAppsCustomQuestionRow({
  question,
}: TrustedAppsCustomQuestionRowProps) {
  const formik = useFormikContext<FormValues>()
  const [open, setOpen] = React.useState(false)
  const index = formik.values.questions.findIndex((q) => q.id === question.id)

  const createQuestionResponse = () => {
    const newResponse = {
      id: Math.random(),
      responseText: '',
      isCreated: true,
    }

    const updatedQuestions = formik.values.questions.map((q) =>
      q.id === question.id
        ? { ...q, responses: [...q.responses, newResponse] }
        : q,
    )

    formik.setFieldValue('questions', updatedQuestions)
  }

  const updateQuestionText = (questionText: string) => {
    const updatedQuestions = formik.values.questions.map((q) =>
      q.id === question.id ? { ...q, questionText } : q,
    )

    formik.setFieldValue('questions', updatedQuestions)
  }

  const removeQuestion = () => {
    const updatedQuestions = formik.values.questions.filter(
      (q) => q.id !== question.id,
    )

    formik.setFieldValue('questions', updatedQuestions)
  }

  const updateQuestionResponseText = (
    responseId: number,
    responseText: string,
  ) => {
    const updatedQuestions = formik.values.questions.map((q) => {
      if (q.id === question.id) {
        const updatedResponses = q.responses.map((r) => {
          return r.id === responseId ? { ...r, responseText } : r
        })
        return { ...q, responses: updatedResponses }
      }
      return q
    })

    formik.setFieldValue('questions', updatedQuestions)
  }

  const removeQuestionResponse = (responseId: number) => {
    formik.setFieldValue(
      'questions',
      formik.values.questions.map((q) => {
        return q.id === question.id
          ? {
              ...q,
              responses: q.responses.filter((r) => r.id !== responseId),
            }
          : q
      }),
    )
  }

  return (
    <div className="flex flex-col gap-2 p-4 rounded-md bg-blue-50">
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-1">
          <Input
            value={question.questionText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateQuestionText(event.target.value)
            }}
            placeholder="Question"
          />

          <button
            type="button"
            className="w-8 h-8 rounded-full flex-shrink-0 flex items-center justify-center"
            onClick={() => {
              removeQuestion()
            }}
          >
            <i className="fas fa-trash text-charcoal hover:text-error-40" />
          </button>
        </div>

        <FormErrorMessage
          name={`questions.${index}.responses`}
          showRequiredOnlyOnSumbit
        />
        <FormErrorMessage
          name={`questions.${index}.questionText`}
          showRequiredOnlyOnSumbit
        />
      </div>

      <div className="w-full flex flex-col shadow-md rounded-md mt-2 bg-white">
        <div
          className="w-full flex justify-between cursor-pointer py-4 px-4 rounded:shadow-lg rounded-md"
          onClick={() => setOpen(!open)}
        >
          <Text fontSize={16}>Options</Text>
          <Icon
            color="textLight"
            className={clsx(
              'fas fa-chevron-up rotate-chevron',
              !open && 'down',
            )}
          />
        </div>
        {open && (
          <div className="flex flex-col items-start px-4 pb-4">
            <div className="flex flex-col gap-2 w-full">
              {question.responses.map((response, responseIndex) => {
                return (
                  <QuestionResponse
                    key={response.id}
                    response={response}
                    responseIndex={responseIndex}
                    questionId={question.id}
                    onChangeText={(text) =>
                      updateQuestionResponseText(response.id, text)
                    }
                    onRemove={() => removeQuestionResponse(response.id)}
                  />
                )
              })}
            </div>
            <Button
              className="mt-4"
              onClick={createQuestionResponse}
              type="button"
            >
              Add Option
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
