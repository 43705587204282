import React from 'react'
import clsx from 'clsx'
import { XIcon } from '@heroicons/react/solid'

import { ToolTip } from 'lib/components'

type Props = {
  name: string
  label: string
  description?: string
  value: any
  onRemove: (value: any) => any
  small?: boolean
}

export default function MultiSelectChip(props: Props) {
  const onRemove = () => props.onRemove(props.value)

  return (
    <div
      className={clsx(
        'flex items-center space-x-1 rounded-full',
        'overflow-hidden text-xs cursor-default mr-2 px-3',
        {
          'py-2 bg-silver': !props.small,
          'py-1 bg-silver': props.small,
        },
      )}
      data-test={`multi-select-option-${props.name}-${props.label}`}
    >
      <p className="text-xs text-gray-900">{props.label}</p>
      {!!props.description && (
        <ToolTip tip={props.description}>
          <i className="fas fa-info-circle text-gray-400 text-sm" />
        </ToolTip>
      )}
      <XIcon
        className={clsx(
          'w-5 h-5 text-gray-700 transform hover:scale-110 hover:text-red-500 cursor-pointer',
        )}
        onClick={onRemove}
        data-test={`remove-multi-select-option-${props.name}-${props.label}`}
      />
    </div>
  )
}
